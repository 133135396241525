import type {Edges} from '../../types';
import {Link} from 'gatsby';
// @flow strict
import React from 'react';
import moment from 'moment';

type Props = {
  edges: Edges,
};

const Feed = ({edges}: Props) => (
  <div>
    {edges.map(edge => (
      <div className="bg-white dark:bg-gray-800 rounded mb-10 pb-5" key={edge.node.fields.slug}>
        <Link to={edge.node.fields.slug}>
          <img src={edge.node.frontmatter.hero} className="rounded-t" />
        </Link>
        <div className="px-5 pt-4 pb-2">
          <time
            className="uppercase text-gray-300 font-semibold text-xs mr-3"
            dateTime={moment(edge.node.frontmatter.date).format('YYYY/MM/DD')}>
            {moment(edge.node.frontmatter.date).format('YYYY/MM/DD')}
          </time>
          <Link to={edge.node.fields.categorySlug} className="font-semibold hover:text-blue-400">
            {edge.node.frontmatter.category}
          </Link>
        </div>
        <h2 className="px-5 mt-0 text-xl font-bold mb-4">
          <Link className="text-gray-900 dark:text-gray-100 hover:text-blue-400" to={edge.node.fields.slug}>
            {edge.node.frontmatter.title}
          </Link>
        </h2>
        {edge.node.frontmatter.description && (
          <p className="px-5 mb-4 text-base">{edge.node.frontmatter.description}</p>
        )}
        <Link className="px-5 hover:text-blue-400" to={edge.node.fields.slug}>
          Read →
        </Link>
      </div>
    ))}
  </div>
);

export default Feed;
