// @flow strict
import React from 'react';
import {getContactHref, getIcon} from '../../../utils';
import Icon from '../../Icon';
// import styles from './Contacts.module.scss';

type Props = {
  contacts: {
    [string]: string,
  },
};

const Contacts = ({contacts}: Props) => (
  <div className="mb-5">
    <ul className="flex items-center space-x-4 mt-3">
      {Object.keys(contacts).map(name =>
        !contacts[name] ? null : (
          <li className="w-4/12" key={name}>
            <a className="" href={getContactHref(name, contacts[name])} rel="noopener noreferrer" target="_blank">
              <Icon name={name} icon={getIcon(name)} />
            </a>
          </li>
        ),
      )}
    </ul>
  </div>
);

export default Contacts;
