import Author from './Author';
import Contacts from './Contacts';
import Copyright from './Copyright';
import Menu from './Menu';
// @flow strict
import React from 'react';
import styles from './Sidebar.module.scss';
import {useSiteMetadata} from '../../hooks';

type Props = {
  isIndex?: boolean,
};

const Sidebar = ({isIndex}: Props) => {
  const {author, copyright, menu} = useSiteMetadata();

  return (
    <div className="p-4 bg-white dark:bg-gray-900 w-full md:w-4/12 border border-gray-200 dark:border-gray-800 rounded place-self-start">
      <div className="">
        <Author author={author} isIndex={isIndex} />
        {/* <Menu menu={menu} /> */}
        <Contacts contacts={author.contacts} />
        <Copyright copyright={copyright} />
      </div>
    </div>
  );
};

export default Sidebar;
