import {Link, withPrefix} from 'gatsby';

// @flow strict
import React from 'react';
// import styles from './Author.module.scss';

type Props = {
  author: {
    name: string,
    bio: string,
    photo: string,
  },
  isIndex: ?boolean,
};

const Author = ({author, isIndex}: Props) => (
  <div className="mb-5">
    <Link to="/">
      <img src={withPrefix(author.photo)} className="rounded-full" width="75" height="75" alt={author.name} />
    </Link>

    {isIndex === true ? (
      <h1 className="font-semibold text-xl my-3">
        <Link className="text-gray-900 dark:text-gray-100" to="/">
          {author.name}
        </Link>
      </h1>
    ) : (
      <h2 className="font-semibold text-xl my-3">
        <Link className="text-gray-100" to="/">
          {author.name}
        </Link>
      </h2>
    )}
    <p>{author.bio}</p>
  </div>
);

export default Author;
