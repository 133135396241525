import React, {useRef, useEffect} from 'react';
import styles from './Page.module.scss';

type Props = {
  title?: string,
  children: React.Node,
};

const Page = ({title, children}: Props) => {
  const pageRef = useRef();

  useEffect(() => {
    pageRef.current.scrollIntoView();
  });

  return (
    <div ref={pageRef} className="w-full md:w-8/12">
      <div className="">
        {title && <h1 className="text-xl font-semibold mb-5">{title}</h1>}
        <div className={`${styles['page__body']}`}>{children}</div>
      </div>
    </div>
  );
};

export default Page;
